<template>
    <onboarding-element>
        <span slot="heading">Set up your password</span>
        <span slot="instruction"
            >Create a new password for<br />
            techymunched@gmail.com
        </span>
        <div slot="input-fields" class="relative">
            <input
                type="text"
                placeholder="Enter password"
                class="w-full border border-color rounded p-3 my-3 focus:border-primary-color-orange focus:outline-none"
            />
            <input
                type="text"
                placeholder="Confirm password"
                class="w-full border border-color rounded p-3 my-3 focus:border-primary-color-orange focus:outline-none"
            />
        </div>
        <span slot="button-text">Set My Password</span>
    </onboarding-element>
</template>

<script>
import OnboardingElement from '@/components/Onboarding.vue'

export default {
    name: 'SetPassword',
    components: { OnboardingElement },
    data() {
        return {
            password: null,
            confirmPassword: null,
        }
    },
}
</script>
